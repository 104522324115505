import React, { lazy, useMemo } from "react";
import { Container } from "@material-ui/core";
import { PropertyContext } from "properties/propertyPage/PropertyContext";
import { PropertyStore } from "properties/_stores/PropertyStore";
import { PROPERTY_PURPOSE, TPropertyListingMdl } from "properties/_models/PropertyMdl";
import { PropertyGallery } from "properties/gallery/PropertyGallery";
import { PropertyHeader } from "properties/propertyPage/property-header";
import { PropertyMeta } from "properties/propertyPage/PropertyMeta";
import { MAIN_RESOURCE } from "_common/resources/BaseResourceStore";
import { PropertyOrUnitGalleryImageModal } from "gallery/PropertyOrUnitGalleryImageModal";
import { PropertyPlanPoint } from "properties/propertyPage/PropertyPlanPoint";
import { LocalLogic } from "properties/localLogic/LocalLogic";
import { Helmet } from "react-helmet-async";
import Suspenser from "_common/loaders/Suspenser";
import { PropertyFloorPlans } from "properties/propertyPage/PropertyFloorPlans";
import { PlacementSlider } from "placements/PlacementSlider";
import { PropertyBreadcrumb } from "properties/propertyPage/PropertyBreadcrumb";
import { _619578458900b8a7f9fc6df7 } from "properties/propertyPage/specificCustomerData/_619578458900b8a7f9fc6df7";
import { PropertyOrUnitPageHeaderTop } from "properties/propertyPage/PropertyOrUnitPageHeaderTop";

type Props = {
    property: TPropertyListingMdl;
};

export const COMPANY_NAME_HIDE_DEMOGRAPHIC = "VIVACITÉ";

const LazyUnitOrPropertyGalleryPage = lazy(() => import("properties/gallery/PropertyOrUnitGalleryPage"));
const LazyNesto = lazy(() => import("properties/nesto/Nesto"));
const LazyPropertySimilarProperties = lazy(() => import("properties/propertyPage/PropertySimilarProperties"));
const LazyPlacementMedia = lazy(() => import("placements/PlacementMediaLoader"));

export function PropertyView(props: Props) {
    const propertyStore = useMemo(() => new PropertyStore(props.property), [props.property]);
    // TODO: sticky banner
    // placementsStore.fetchBannerProperties(
    //     getPlacementPropertyType(props.property.purpose, props.property.type),
    //     props.property._id,
    // );

    return (
        <PropertyContext propertyStore={propertyStore}>
            <Helmet
                link={[
                    {
                        rel: "preload",
                        as: "image",
                        href: props.property?.photos?.[0]?.url,
                    },
                ]}
            />
            {propertyStore.property._id === "623b861d3a816ce875b67a7f" && (
                <Helmet>
                    <script type="application/ld+json">{_619578458900b8a7f9fc6df7}</script>
                </Helmet>
            )}
            <PropertyMeta property={props.property} />
            <PropertyOrUnitPageHeaderTop item={props.property} type={MAIN_RESOURCE.PROPERTIES} />
            <Container>
                <div className={"mt_10"}>
                    <PropertyBreadcrumb />
                </div>
                <PropertyGallery />
                <div className={"mb_40 flex_row"}>
                    <PropertyHeader />
                </div>
                <PropertyPlanPoint />
                {!propertyStore.property.isLocalLogicDisabled && (
                    <div className="mt_60 mb_20">
                        <LocalLogic property={props.property} />
                    </div>
                )}
                <Suspenser noText noSpinner>
                    <LazyPlacementMedia />
                </Suspenser>
            </Container>
            {props.property.purpose === PROPERTY_PURPOSE.BUY && (
                <div className={"mt_40 pv_40"}>
                    <Container>
                        <Suspenser noText noSpinner fallback={<div style={{ width: "100%", height: 85 }}> </div>}>
                            <LazyNesto price={props.property.price.min} province={props.property.address.province} />
                        </Suspenser>
                    </Container>
                </div>
            )}
            {propertyStore.property.floorPlans.length !== 0 && (
                <Container className={"mb_25 mt_50 mb_40"}>
                    <PropertyFloorPlans />
                </Container>
            )}
            <Suspenser noSpinner noText>
                <LazyPropertySimilarProperties premium />
            </Suspenser>
            <Suspenser noSpinner noText>
                <LazyPropertySimilarProperties />
            </Suspenser>
            <Suspenser noText noSpinner>
                <LazyUnitOrPropertyGalleryPage
                    resource={MAIN_RESOURCE.PROPERTIES}
                    store={propertyStore}
                    propertyGooglePosition={{
                        lat: propertyStore.property.location.coordinates[1],
                        lng: propertyStore.property.location.coordinates[0],
                    }}
                />
            </Suspenser>
            <PropertyOrUnitGalleryImageModal resource={MAIN_RESOURCE.PROPERTIES} store={propertyStore} />
            <PlacementSlider />
        </PropertyContext>
    );
}
